@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Roboto+Mono:wght@500;700&display=swap');

:root{
  --primary-color: #196cdb;
}

html{
  scrollbar-color: #acacac #fbfbfb;
  scrollbar-width: thin !important;
}

html::-webkit-scrollbar {
  width: 15px;
  border-radius: 25px;
}

html::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 25px;
}

html::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 25px;
  border: 3px solid #f1f1f1;
}

html::-webkit-scrollbar-thumb:hover {
  background: #555;
} 

body {
  margin: 0;
  font-family: "Google Sans",Roboto,Arial,sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  background-color: #fbfbfb;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  padding-right: 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiTableCell-root{
  letter-spacing: 0px !important;
}

.MuiTypography-root{
  letter-spacing: 0px !important;
}

.MuiDrawer-docked {
  background-color: #fbfbfb;
  margin-right: 15px !important;
  padding-right: 5px !important;
  padding-top: 5px !important;
}

.MuiButtonBase {
  color: #000 !important;
}

.MuiPaper-elevation1 {
  box-shadow: 0px 1px 2px 0px rgba(66,66,66,.30) ,0px 2px 6px 2px rgba(66,66,66,.15) !important;
}

.MuiTable-root {
  border-collapse: unset !important;
}

.MuiTableHead-root {
  height: 45px !important;
}

.MuiFilledInput-underline::after {
  border-bottom: 2px solid #196cdb !important;
}

.MuiListItemIcon-root {
  color: currentColor !important;
}

.MuiButton-textPrimary {
  color: #fff !important;
  background-color: #196cdb !important;
  margin-left: 10px !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
  text-transform: none !important;
}

.MuiButton-textPrimary:hover {
  background-color: #196cdbb5  !important;
}

.MuiFab-primary{
  background-color: #196cdb !important;
}

.MuiCheckbox-colorSecondary.Mui-checked{
  color: #196cdb !important;
}

.MuiCheckbox-colorSecondary.Mui-checked:hover {
  background-color: #196cdb1c !important;
}

.ra-delete-button {
  color: #fff !important;
  background-color: #f80303 !important;
  margin-left: 10px !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
}

#GoogleStyleDeleteButton.ra-delete-button {
  color: #3d3d3d !important;
  background-color: transparent !important;
  margin-left: 10px !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
  min-width: 40px;
  min-height: 40px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition: ease 0.6s;
}

#GoogleStyleMuiDeleteButton.MuiButtonBase-root {
  color: #3d3d3d !important;
  background-color: transparent !important;
  margin-left: 10px !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
  min-width: 40px;
  min-height: 40px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition: ease 0.6s;
}

#GoogleStyleButtonText.MuiButton-text {
  color: #fff !important;
  background-color: #196cdb !important;
  margin-left: 10px !important;
  padding-right: 15px !important;
  padding-left: 15px !important;
  text-transform: none !important;
  padding-top: 5px;
  padding-bottom: 5px;
}

#GoogleStyle-MuiButton.MuiButtonBase-root {
  color: #3d3d3d !important;
  background-color: transparent !important;
  margin-left: 10px !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
  min-width: 40px;
  min-height: 40px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition: ease 0.6s;
}

#GoogleStyleButton.MuiButton-textPrimary {
  color: #3d3d3d !important;
  background-color: transparent !important;
  margin-left: 10px !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
  min-width: 40px;
  min-height: 40px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition: ease 0.6s;
}

#GoogleStyleBulkButton.MuiButton-textPrimary {
  color: #3d3d3d !important;
  background-color: transparent !important;
  margin-left: 10px !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
  min-width: 40px;
  min-height: 40px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition: ease 0.6s;
}

#GoogleStyleBackButton.MuiButton-root {
  color: #3d3d3d !important;
  background-color: transparent !important;
  margin-left: 10px !important;
  padding-left: 17px !important;
  padding-right: 5px !important;
  min-width: 40px;
  min-height: 40px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition: ease 0.6s;
}

#ConditionsDraftButton.MuiButton-root {
  color: #3d3d3d !important;
  background-color: transparent !important;
  margin-left: 10px !important;
  padding-left: 17px !important;
  padding-right: 5px !important;
  min-width: 40px;
  min-height: 40px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition: ease 0.6s;
}

#ConditionsPublishButton.MuiButton-root {
  color: #3d3d3d !important;
  background-color: transparent !important;
  margin-left: 10px !important;
  padding-left: 17px !important;
  padding-right: 5px !important;
  min-width: 40px;
  min-height: 40px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition: ease 0.6s;
}

#GoogleStylePagination.MuiButton-root {
  color: #3d3d3d !important;
  background-color: transparent !important;
  margin-left: 10px !important;
  min-width: 40px;
  min-height: 40px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition: ease 0.6s;
}

#GoogleStyleDownload.MuiButtonBase-root {
  color: #3d3d3d !important;
  background-color: transparent !important;
  margin-left: 10px !important;
  min-width: 40px;
  min-height: 40px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition: ease 0.6s;
}

#GoogleStyleOffer.MuiButtonBase-root {
  color: #3d3d3d !important;
  background-color: transparent !important;
  margin-left: 10px !important;
  min-width: 40px;
  min-height: 40px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition: ease 0.6s;
}

#GoogleStyleBasket.MuiButtonBase-root {
  color: #fff !important;
  background-color: transparent !important;
  margin-left: 10px !important;
  min-width: 40px;
  min-height: 40px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition: ease 0.6s;
}

#GoogleStyleMenuFaq.MuiButtonBase-root {
  color: #757575  !important;
  background-color: transparent !important;
  margin-left: 10px !important;
  min-width: 40px;
  min-height: 40px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition: ease 0.6s;
  padding-left: 19px;
}

#GoogleStylePaginationDisabled.MuiButton-root {
  color: #afafaf !important;
  background-color: transparent !important;
  margin-left: 10px !important;
  min-width: 40px;
  min-height: 40px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition: ease 0.6s;
}

.ra-delete-button:hover {
  background-color: hsl(0, 100%, 64%) !important;
}

#GoogleStyleDeleteButton.ra-delete-button:hover {
  background-color: hsla(0, 100%, 51%, 0.178) !important;
  color:hsl(0, 100%, 50%) !important;
}

#GoogleStyleMuiDeleteButton.MuiButtonBase-root:hover {
  background-color: hsla(0, 100%, 51%, 0.178) !important;
  color:hsl(0, 100%, 50%) !important;
}

#GoogleStyleButton.MuiButton-textPrimary:hover {
  background-color: hsla(0, 0%, 80%, 0.51) !important;
}

#GoogleStyle-MuiButton.MuiButtonBase-root:hover {
  background-color: hsla(0, 0%, 80%, 0.51) !important;
}

#GoogleStyleBulkButton.MuiButton-textPrimary:hover {
  background-color: hsla(0, 100%, 51%, 0.178) !important;
  color:hsl(0, 100%, 50%) !important;
}

#GoogleStyleBackButton.MuiButton-root:hover {
  background-color: hsla(0, 0%, 80%, 0.51) !important;
}

#ConditionsPublishButton.MuiButton-root:hover {
  background-color: hsla(0, 0%, 80%, 0.51) !important;
}

#ConditionsDraftButton.MuiButton-root:hover {
  background-color: hsla(0, 0%, 80%, 0.51) !important;
}

#GoogleStylePagination.MuiButton-root:hover {
  background-color: hsla(0, 0%, 80%, 0.51) !important;
}

#GoogleStyleDownload.MuiButtonBase-root:hover {
  background-color: hsla(0, 0%, 80%, 0.51) !important;
}

#GoogleStyleOffer.MuiButtonBase-root:hover {
  color: #196cdb !important;
  background-color: #196cdb36  !important;
}

#GoogleStyleBasket.MuiButtonBase-root:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

#GoogleStyleToolBar.MuiToolbar-root{
  background-color: #fbfbfb;
  margin-top: 15px;
  margin-bottom: 7px;
  border-radius: 10px;
  padding-bottom: 4px;
  padding-top: 5px;
}

.page-number{
  width: 30px !important;
  min-width: 30px !important;
  background-color: #f0f0f0 !important;
  color: #000 !important;
  border-radius: 50% !important;
  margin-left: 10px !important;
  height: 30px;
}

.page-number:hover{
  background-color: rgb(219, 219, 219) !important;
  box-shadow: none !important;
}
.page-number:focus{
  background-color: rgb(219, 219, 219) !important;
}
.page-number:active{
  background-color: rgb(219, 219, 219) !important;
}

.MuiTablePagination-toolbar {
  padding-right: 20px !important;
}

.next-page {
  width: 30px !important;
  min-width: 30px !important;
  background-color: #f0f0f0 !important;
  color: #000 !important;
  border-radius: 50% !important;
  margin-left: 10px !important;
  height: 30px;
}

.next-page:hover{
  background-color: rgb(219, 219, 219) !important;
  box-shadow: none !important;
}

.previous-page {
  width: 30px !important;
  min-width: 30px !important;
  background-color: #f0f0f0 !important;
  color: #000 !important;
  border-radius: 50% !important;
  margin-left: 10px !important;
  height: 30px;
}

.previous-page:hover{
  background-color: rgb(219, 219, 219) !important;
  box-shadow: none !important;
}

.MuiFormLabel-root {
  margin-top: 0px !important;
  margin-left: 0px !important;
}
.MuiFormLabel-root {
  padding-bottom: 0px !important;
}

.MuiCard-root {
  overflow: hidden;
}

.MuiAvatar-colorDefault {
  color: #3d3d3d !important;
  background-color: transparent !important;
}
.MuiAvatar-root {
  z-index: -5 !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #196cdb !important;
}

.RaBulkActionsToolbar-toolbar-59 {
  color: #000000 !important;
  background-color: rgb(234, 234, 234) !important;
}

.RaImageInput-dropZone-88 {
  background: #e6e6e6 !important;
  border: 1px dashed #3d3d3d !important;
  border-radius: 3px;
}

.react-calendar, .react-calendar *, .react-calendar ::before, .react-calendar ::after {
  text-decoration: none;
  text-transform: capitalize;
}

.react-calendar {
    border: 1px solid #fff !important;
    border-radius: 10px !important;
    box-shadow: 1px 1px 10px #bbb6b6 !important;
}

.react-calendar__navigation__prev2-button {
  border-top-left-radius: 10px !important;
}

.react-calendar__navigation__next2-button {
  border-top-right-radius: 10px !important;
}

.react-calendar__tile--active {
  background: #196cdb !important;
  color: white;
  height: 50px;
  border-radius: 50%;
}

.react-calendar__month-view__weekdays {
  font-size: 0.85em !important;
  font-weight: normal !important;
}

.react-calendar__navigation__prev2-button {
  display: none;
}  

.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__next-button:hover {
  border-top-right-radius: 10px;
}

.react-calendar__navigation__prev-button:hover {
  border-top-left-radius: 10px;
}

.react-calendar, .react-calendar *, .react-calendar ::before, .react-calendar ::after {
  font-size: 14px;
}

.react-calendar__month-view__weekdays {
  margin-bottom: 8px;
}

.react-calendar__tile {
  height: 50px !important;
}

.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
  border-radius: 50px !important;
}

.react-calendar__navigation button {
  border-radius: 3px;
}

.react-calendar__month-view__days__day--weekend {
  color: #000 !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575 !important;
}

.react-calendar__tile--now {
  background: #ffff76;
  border-radius: 50%;
}

.MuiInputBase-root.Mui-disabled {
  color: #3d3d3d !important;
}

.MuiInput-underline.Mui-disabled::before {
  border-bottom-style: none !important;
}
.MuiInput-underline::before {
  border-bottom: none !important;
}

.MuiTableRow-root{
  height: 50px !important;
}

textarea[id=dec_general],
textarea[id=dec_programacion],
textarea[id=dec_reposicion],
textarea[id=dec_concentracion],
textarea[id=observaciones],
textarea[id=facturacion],
textarea[id=envio],
textarea[id=pago],
textarea[id=portes],
textarea[id=pedido_min],
textarea[id=category]{
    font-size: 15px;
    color: #424242;
}
span[for=observaciones]{
  font-size: 15px;
  line-height: 1.2;
  color: #424242;
}

span[for=observaciones] p{
  margin-bottom: 0px !important;
}

span[for=observaciones] ul{
  margin-top: 5px !important;
  padding-left: 20px !important;
}

span[for=observaciones] ul li a{
  color: #196cdb !important;
}

label[for=observaciones]{
  font-size: 1.7em;
  color: #000 !important
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: #196cdb !important;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track{
  background-color: #588edb  !important;
}

.Mui-error::after {
  transform: scaleX(1) !important;
  border-bottom-color: #f44336 !important;
}

.MuiButton-containedPrimary {
  background-color: #3d3d3d !important;
}

.MuiButton-containedPrimary.Mui-disabled {
  background-color: #196ddba2 !important;
  color: #fff !important
}

.MuiButton-outlinedPrimary {
  color: #3d3d3d !important;
  border: 1px solid #3d3d3d62 !important;
}

div[data-test|="bulk-actions-toolbar"]{
  background-color: #196cdb24 !important;
  color: #196cdb  !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

div[data-test|="bulk-actions-toolbar"] div button{
  color: #196cdb  !important;
}

div[data-test|="bulk-actions-toolbar"] .MuiToolbar-root{
  margin-bottom: 15px;
}

/* ----------- TinyEMC Editor ------------ */ 

.tox-tinymce{
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid #a0a0a0 !important;
}
.tox-mbtn:hover{
  background: #196cdb1c !important;
  color: #196cdb !important;
  border-radius: 4px !important;
}
.tox-tbtn:hover{
  background: #196cdb1c !important;
  color: #196cdb !important;
  border-radius: 4px !important;
}
.tox-tbtn:hover svg{
  fill: #196cdb !important;
}
.tox-tbtn--enabled{
  background: #196cdb1c !important;
  color: #196cdb !important;
  border-radius: 4px !important;
}
.tox-tbtn--enabled svg{
  fill: #196cdb !important;
}
.tox-mbtn--active{
  background-color: #196cdb1c !important;
  color: #196cdb !important;
  border-radius: 4px !important;
}
.tox-collection__item--active{
  background-color: #196cdb1c;
  color: #196cdb !important;
  border-radius: 4px !important;
}
.tox:not([dir="rtl"]) .tox-collection--list .tox-collection__item > :not(:first-child){
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.tox-editor-header{
  border-bottom: 1px solid #a0a0a0 !important;
}
.tox-statusbar{
  border-top: 1px solid #a0a0a0 !important;
}
.tox .tox-listboxfield .tox-listbox--select, .tox .tox-textarea, .tox .tox-textfield, .tox .tox-toolbar-textfield{
  margin-top: 5px !important;
  border-color: #a0a0a0 !important;
  border-radius: 4px !important;
}
.tox.tox-tinymce.tox-fullscreen {
  margin-top: 50px !important; 
}
.tox-fullscreen {
  height: calc(100% - 50px) !important;
}
.tox-rgba-preview {
  border-radius: 4px;
}
.tox-sv-palette-spectrum {
  border-radius: 4px;
}
.tox-hue-slider-spectrum{
  border-radius: 4px;
}
.tox-dialog__title, .tox-label, .tox-dialog__body-nav-item{
  font-family: "Google Sans",Roboto,Arial,sans-serif !important;
}
.tox-button{
  font-weight: 500 !important;
  font-family: "Google Sans",Roboto,Arial,sans-serif !important;
  border-radius: 4px !important;
}
.tox .tox-dialog__body-nav-item--active {
  padding: 5px !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}
.tox .tox-dialog__body-nav-item {
  padding: 5px !important;
}

.tox:not([dir="rtl"]) .tox-color-input span {
  margin-top: 5px;
}

/* --------------------------------------- */ 

.MuiSelect-select:focus{
  border-radius: 5px !important
}

.MuiCard-root {
  border-radius: 10px !important;
}

.MuiTableCell-sizeSmall:last-child {
  border-top-right-radius: 10px !important;
}

.MuiTableCell-paddingNone {
  border-top-left-radius: 10px !important;
}

.MuiTableCell-paddingCheckbox {
  border-top-left-radius: 10px !important;
}

.MuiAccordion-rounded {
  border-radius: 10px !important;
}

.MuiFormLabel-root.Mui-focused{
  color: #3d3d3d !important;
}

div[data-testid="dropzone"] {
  border: 1px dashed;
  border-radius: 3px;
}

.MuiListItem-button:hover {
  background-color: rgba(229, 229, 229, 0.54) !important;
}

.filter-field[data-source="jornadas_compras"] {
  margin-bottom: 5px !important;
  margin-left: 15px
}

.MuiTableCell-sizeSmall {
  border-top-left-radius: 10px !important;
}

span[aria-label="OrderUserInfo"]{
  font-size: 15px;
  text-transform: capitalize;
  font-weight: normal;
  cursor: pointer;
  color: #196cdb;
}

.sizechart-scroll {
  position: relative;
  font-family: Roboto, sans-serif;
  margin: auto;
  white-space: nowrap;
  overflow: auto;
  z-index: 1;
  scrollbar-width: thin;
  scrollbar-color: #00bcb4 #fff;
}

#FixedSideNavBar::-webkit-scrollbar{
  width: 0.5rem;
}

#FixedSideNavBar::-webkit-scrollbar-track{
  background: #fbfbfb;
}

#FixedSideNavBar::-webkit-scrollbar-thumb{
  background-color: #acacac;
  border-radius: 10px;
}

#FixedSideNavBar::-moz-scrollbar{
  width: 0.5rem;
}

#FixedSideNavBar::-moz-scrollbar-track{
  background: #fbfbfb;
}

#FixedSideNavBar::-moz-scrollbar-thumb{
  background-color: #acacac;
  border-radius: 10px;
}

.MuiLinearProgress-root{
  background-color:#cedef5 !important;
}
.MuiLinearProgress-bar{
  background-color: #196cdb !important;
}

#hideFilterTooltip { animation: css 0s 2s forwards; }
@keyframes css  { to { visibility: hidden; } }

.error {
  color: var(--primary-color);
  font-size: 10rem;
  position: relative;
  line-height: 1;
  width: 100%;
  font-weight: 800;
  font-family: 'Roboto Mono', monospace;
}
.error:before {
  content: attr(data-text);
  position: absolute;
  left: 8px;
  text-shadow: 1px 0 #4e73df;
  top: 0;
  color: var(--primary-color);
  background: #fbfbfb;
  overflow: hidden;
  clip: rect(0,900px,0,0);
  animation: noise-anim-2 3s infinite linear alternate-reverse;
  width: 100%;
}
.error:after {
  content: attr(data-text);
  position: absolute;
  left: 14px;
  text-shadow: -1px 0 #e74a3b;
  top: 0;
  color: var(--primary-color);
  background: #fbfbfb;
  overflow: hidden;
  clip: rect(0,900px,0,0);
  animation: noise-anim 2s infinite linear alternate-reverse;
  width: 100%;
}

@-webkit-keyframes noise-anim{
  0%{clip:rect(89px,9999px,100px,0)}
  5%{clip:rect(4px,9999px,62px,0)}
  10%{clip:rect(43px,9999px,16px,0)}
  15%{clip:rect(2px,9999px,68px,0)}
  20%{clip:rect(12px,9999px,6px,0)}
  25%{clip:rect(89px,9999px,25px,0)}
  30%{clip:rect(15px,9999px,38px,0)}
  35%{clip:rect(57px,9999px,97px,0)}
  40%{clip:rect(24px,9999px,72px,0)}
  45%{clip:rect(81px,9999px,3px,0)}
  50%{clip:rect(93px,9999px,26px,0)}
  55%{clip:rect(68px,9999px,85px,0)}
  60%{clip:rect(43px,9999px,28px,0)}
  65%{clip:rect(44px,9999px,11px,0)}
  70%{clip:rect(17px,9999px,33px,0)}
  75%{clip:rect(74px,9999px,66px,0)}
  80%{clip:rect(100px,9999px,4px,0)}
  85%{clip:rect(92px,9999px,31px,0)}
  90%{clip:rect(93px,9999px,98px,0)}
  95%{clip:rect(23px,9999px,77px,0)}
  100%{clip:rect(92px,9999px,8px,0)}
}

@keyframes noise-anim{
  0%{clip:rect(89px,9999px,100px,0)}
  5%{clip:rect(4px,9999px,62px,0)}
  10%{clip:rect(43px,9999px,16px,0)}
  15%{clip:rect(2px,9999px,68px,0)}
  20%{clip:rect(12px,9999px,6px,0)}
  25%{clip:rect(89px,9999px,25px,0)}
  30%{clip:rect(15px,9999px,38px,0)}
  35%{clip:rect(57px,9999px,97px,0)}
  40%{clip:rect(24px,9999px,72px,0)}
  45%{clip:rect(81px,9999px,3px,0)}
  50%{clip:rect(93px,9999px,26px,0)}
  55%{clip:rect(68px,9999px,85px,0)}
  60%{clip:rect(43px,9999px,28px,0)}
  65%{clip:rect(44px,9999px,11px,0)}
  70%{clip:rect(17px,9999px,33px,0)}
  75%{clip:rect(74px,9999px,66px,0)}
  80%{clip:rect(100px,9999px,4px,0)}
  85%{clip:rect(92px,9999px,31px,0)}
  90%{clip:rect(93px,9999px,98px,0)}
  95%{clip:rect(23px,9999px,77px,0)}
  100%{clip:rect(92px,9999px,8px,0)}
}

.bubble {
  position: relative;
  font-size: 20px;
  line-height: 24px;
  background: #f2f2f2;
  border-radius: 10px;
  padding: 24px;
  text-align: center;
  color: #000;
  box-shadow: 0px 1px 2px 0px rgba(66,66,66,.30) ,0px 2px 6px 2px rgba(66,66,66,.15) !important;
  margin-bottom: 35px;
  margin-top: 15px;
  display: flex;
  min-height: 45px;
}

.bubble:hover button{
  display: flex
}

.bubble-bottom-left:before {
  content: '';
	position: absolute;
	width: 0;
	height: 0;
	bottom: 100%;
	left: 1.0em; 
	border: .70rem solid transparent;
	border-top: none;

	border-bottom-color: #f2f2f2;
	filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, .1));
}

.hidden-menu {
  width: 55px !important; 
  transition: width 195ms ease-in-out;
}

.hidden-menu .MuiButtonBase-root {
  width: 55px !important;
  transition: width 195ms ease-in-out;
}

.MuiPickersToolbar-toolbar {
  background-color: #196cdb !important;
}

.MuiPickersDay-daySelected{
  background-color: #196cdb !important;
}

.MuiTypography-colorPrimary{
  color: #196cdb !important;
}

.MuiInput-underline::after{
  border-color: #196cdb !important;;
}

@keyframes StatePendingBlink {
  0%, 49% {
    border: 1px solid #757575;
    background-color: #7575751c;
    color: #757575;
  }
  50%, 100% {
    border: 1px solid #eaa20f;
    background-color: #fff0bc;
    color: #eaa20f;
  }
}